<template>
  <div>
    <div ref="headHeight">
      <el-menu mode="horizontal" @select="handleSelect"
               active-text-color="#5cb6ff">
        <div style="float: right;height: 6vh;line-height: 6vh;margin-right: 2%">
          <el-dropdown trigger="click" @command="handleCommand">
          <span class="el-dropdown-link">
            <b>{{emp.empName}}</b><i class="el-icon-arrow-down el-icon--right"></i>
          </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="1" style="text-align: center">{{ emp.empPhone }}</el-dropdown-item>
              <el-dropdown-item command="2" style="text-align: center">修改密码</el-dropdown-item>
              <el-dropdown-item command="3" style="text-align: center">退出登录</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
        <el-menu-item class="list-item" :index="item.url" v-for="item in items" :key="item.url">
          <el-tooltip :content='item.annotation' placement="top"  >
            <i :class="item.icon"></i>
          </el-tooltip>
          <span slot="title">{{ item.title }}</span>
        </el-menu-item>
      </el-menu>
    </div>
    <empPwdUpdate v-if="dialog.pwdUpdateState" :state="dialog.pwdUpdateState" @close="dialog.pwdUpdateState=false"/>
  </div>
</template>

<script>
import empPwdUpdate from "@/pages/emp/emp-pwd-update"
export default {
  name: "main-header",
  data() {
    return {
      items: this.$store.state.routerView.moduleList,
      emp:JSON.parse(localStorage.getItem("emp")),
      dialog:{
        pwdUpdateState:false
      },
    }
  },
  created() {
    console.log(this.emp)
  },
  components:{
    empPwdUpdate
  },
  methods: {
    handleSelect(index) {
      if (index === this.$route.path) return;
      let sidebars = this.$store.state.routerView.moduleList.filter(s => s.url === index)[0].child
      this.$store.commit("setSidebars", sidebars)
      this.defaultMenu(index)
    },
    defaultMenu(index) {
      let route = this.items.find(item => item.url === index)
      this.$store.commit("setMenus", {route})
    },
    //选择顶部右上角菜单
    handleCommand(path){
      switch (path){
        case "1":
          navigator.clipboard.writeText(this.emp.empPhone);
          this.$message.success("复制手机号码成功！")
          return;
        case "2":
          this.dialog.pwdUpdateState=true;
          return;
        case "3":
          this.logOut();
          return;
      }
    },
    //退出
    logOut(){
      localStorage.clear();
      this.$store.commit("clearAll")
      this.$router.push("/login");
    },
  }
}
</script>

<style scoped>
.el-dropdown-link {
  cursor: pointer;
  color: #4091ff;
}
.el-icon-arrow-down {
  font-size: 12px;
}
/deep/ .el-menu--horizontal>.el-menu-item {
  height: 6vh!important;
  line-height: 6vh;
}
</style>