<template>
<!--    <el-container>-->
<!--          <el-aside>-->
            <div class="aside">

              <el-menu  class="sidebar-el-menu" :default-active="$route.path" router
                        background-color="#001428"
                        text-color="#bbbbbb"
                        mode="vertical"
                        active-text-color="#FFFFFF"
                        style="height: 100vh">
                <el-menu-item index="4" style="pointer-events: none">
                  <img class="logo_img" src="https://isasaerp-img-1304365928.cos.ap-shanghai.myqcloud.com/logo.png">
                  <span slot="title" class="logo_name">ISASA</span>
                </el-menu-item>
                <template v-for="item in sidebars">
                  <el-submenu :index="item.url" v-if="item.child!==undefined && item.child.length > 0" :key="item.id">
                    <template slot="title">
                      <el-tooltip  :content='item.annotation' placement="top">
                        <i :class="item.icon"></i>
                      </el-tooltip>
                      <span>{{ item.title }}</span>
                    </template>
                    <sub-item :items="item.child" />
                  </el-submenu>
                  <el-menu-item :index="item.url" v-else :key="item.id">
                    <el-tooltip  :content='item.annotation' placement="top"  >
                      <i :class="item.icon"></i>
                    </el-tooltip>
                    <span slot="title">{{ item.title }}</span>
                  </el-menu-item>
                </template>
<!--                <sub-item :items="sidebars"/>-->
              </el-menu>
            </div>
<!--          </el-aside>-->
<!--      <el-main style="height: 100vh">-->
<!--        <el-tabs-->
<!--            type="border-card"-->
<!--            v-model="activeIndex"-->
<!--            closable-->
<!--            v-if="openTabs.length"-->
<!--            @tab-click='tabClick'-->
<!--            @tab-remove='tabRemove'>-->
<!--          <el-tab-pane-->
<!--              :key="item.name"-->
<!--              v-for="item in openTabs"-->
<!--              :name="item.name">-->
<!--            <template #label><i :class="item.icon"></i> {{item.label}}</template>-->
<!--          </el-tab-pane>-->
<!--        </el-tabs>-->
<!--        <div class="content-wrap" v-show="openTabs.length">-->
<!--          <router-view />-->
<!--        </div>-->
<!--      </el-main>-->
<!--    </el-container>-->
</template>

<script>
import subItem from "@/components/main/sub-item"

export default {
  name: "main-sidebar",
  components: {
    subItem
  },
  data() {
    return {
      items:[],
      //顶部面包屑
      breadcrumb:[],
    }
  },
  watch:{
    $route(val){
      // document.title = val.meta.title
      val.matched.shift();
      // this.breadcrumb=val.matched;
      // let child=this.$store.state.menus.route.child;
      // this.items = child===undefined?undefined:child;
      if (val.matched.length === 3) {
        let flag = false
        for (let item of this.$store.state.openTabs) {
          if (item.name === val.fullPath) {
            this.$store.commit("setActiveIndex", val.fullPath)
            flag = true
            break
          }
        }
        if (!flag) {
          this.$store.commit("addTab", {label: val.meta.title, name: val.fullPath, icon: val.meta.icon})
          this.$store.commit("setActiveIndex", val.fullPath)
        }
      }
    },
  },
  computed: {
    activeIndex:{
      get() {
        return this.$store.state.activeIndex;
      },
      set(val) {
        this.$store.commit('setActiveIndex', val);
      }
    },
    openTabs() {
      return this.$store.state.openTabs;
    },
    sidebars() {
      return this.$store.state.sidebars;
    }
  },
  // props:{
  //   headerHeight:{
  //     type:Number,
  //     required:true
  //   }
  // },
  created() {
    if (this.$store.state.menus.route!==undefined)
      this.items=this.$store.state.menus.route.child
    // console.log(this.$store.state.menus)
    // console.log(this.$store.state.menus.route)
    // let child=this.$store.state.menus.route.child;
    // this.items = child===undefined?undefined:child;
  },
  methods: {
    tabClick(){
      this.$router.push({path: this.activeIndex});
    },
    tabRemove(val) {
      let index = this.openTabs.findIndex((item) => item.name === val)
      this.$store.commit('deleteTab', val)
      if (this.activeIndex === val) {
        if (this.openTabs.length >= 1) {
          if (index === this.openTabs.length) {
            this.$store.commit('setActiveIndex', this.openTabs[index-1].name);
          } else {
            this.$store.commit('setActiveIndex', this.openTabs[index].name);
          }
          this.$router.push({path: this.activeIndex});
        } else {
          this.activeIndex = ""
        }
      }
    }
  }

}
</script>

<style scoped>
.aside::-webkit-scrollbar {
  display: none;
}
.aside {
  height: 100vh;
  overflow: auto;
}
.el-main {
  padding: 0px !important;
}
.content-wrap{
  padding-left: 15px;
  border: 0px;
  border-top: none;
}
.el-tabs.el-tabs--border-card {
  box-shadow: none;
  border-bottom: none;
  border-left: 0px;
  border-top: 0px;
  border-right: 0px;
}
/deep/ .el-tabs--border-card>.el-tabs__content {
  padding: 0px!important;
}
/deep/ .el-tabs--border-card>.el-tabs__header {
  background-color: #FFFFFF;
}
.el-tabs--card>.el-tabs__header .el-tabs__nav{
  border-top: 0px;
}
.logo_img{
  width: 32px;
  height: 32px;
  margin-left: 10%;
}
.logo_name{
  color: #FFFFFF;
  font-family: Helvetica Neue,Helvetica,PingFang SC,Hiragino Sans GB,Microsoft YaHei,"\5fae\8f6f\96c5\9ed1",Arial,sans-serif;
  margin-left: 7px;
  font-size: 15px;
}
/deep/ .el-menu-item.is-active{
  background-color: #409EFF!important;
}
/deep/ .el-submenu.is-active .el-submenu__title {
  color: #FFFFFF!important;
}
/deep/ .el-submenu.is-active .el-submenu__title i {
  color: #FFFFFF!important;
}
/deep/ .el-menu-item:hover{
  color: #FFFFFF!important;
}
/deep/ .el-submenu__title:hover{
  color: #FFFFFF!important;
}
</style>