<template>
  <div class="main">
    <el-container>
      <el-aside width="205px">
        <main-sidebar/>
      </el-aside>
      <el-container>
        <el-header height="6vh">
          <mainHeader/>
        </el-header>
        <el-main style="height: 94vh;background-color: #f5f7f9">
          <div class="tab">
            <el-tabs
                type="card"
                v-model="activeIndex"
                closable
                v-if="openTabs.length"
                @tab-click='tabClick'
                @tab-remove='tabRemove'>
              <el-tab-pane
                  :key="item.name"
                  v-for="item in openTabs"
                  :name="item.name">
                <template #label><i :class="item.icon"></i> {{item.label}}</template>
              </el-tab-pane>
            </el-tabs>
          </div>
          <div class="main_container" v-show="openTabs.length">
            <router-view/>
          </div>
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script>
import mainHeader from "@/components/main/main-header"
import mainSidebar from "@/components/main/main-sidebar"
import router from "../../router/home";

export default {
  data() {
    return {
    }
  },
  components: {
    mainHeader,
    mainSidebar
  },
  computed: {
    activeIndex:{
      get() {
        return this.$store.state.activeIndex;
      },
      set(val) {
        this.$store.commit('setActiveIndex', val);
      }
    },
    openTabs() {
      return this.$store.state.openTabs;
    },
    sidebars() {
      return this.$store.getters.openTabs;
    }
  },
  created() {
    // console.log(this.openTabs)
    // if (this.openTabs.length === 0) {
    //   this.$router.push("/index")
    // }
    // this.$router.push("/home")
    this.getUserInfo();
    // location.reload()
  },
  methods:{
    getUserInfo() {

    },
    tabClick(){
      this.$router.push({path: this.activeIndex});
    },
    tabRemove(val) {
      if (val === "/home") {
        return
      }
      let index = this.openTabs.findIndex((item) => item.name === val)
      this.$store.commit('deleteTab', val)
      if (this.activeIndex === val) {
        if (this.openTabs.length >= 1) {
          if (index === this.openTabs.length) {
            this.$store.commit('setActiveIndex', this.openTabs[index-1].name);
          } else {
            this.$store.commit('setActiveIndex', this.openTabs[index].name);
          }
          this.$router.push({path: this.activeIndex});
        } else {
          this.activeIndex = ""
        }
      }
    }
  }
}
</script>

<style scoped>
/deep/ .el-header{
  padding: 0 0px !important;
}
.main_container{
  margin-top: 6px;
  background-color: #FFFFFF;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 3px;
}
/deep/ .el-main{
  padding: 6px 10px 10px 10px!important;
}
.tab /deep/ .el-tabs--border-card>.el-tabs__content {
  padding: 0px !important;
}
.tab /deep/ .el-tabs__header {
  margin: 0px !important;
}
.tab /deep/ .el-tabs--card>.el-tabs__header{
  border-bottom: none;
}
.tab /deep/ .el-tabs--card>.el-tabs__header .el-tabs__nav{
  border: none;
}
.tab /deep/ .el-tabs--card>.el-tabs__header .el-tabs__item:not(:first-child) {
  border-left: 6px solid #f5f7f9;
}
.tab /deep/ .el-tabs__item {
  background-color: #FFFFFF;
  height: 32px;
  line-height: 32px;
  padding: 0 15px;
}

</style>