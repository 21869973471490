<template>
  <el-dialog
      title="修改密码"
      width="40%"
      :visible.sync="dialogState"
      :modal-append-to-body="false"
      @close="close"
      center>
    <el-form label-width="100px" :model="form" :rules="rules" style="margin-left: 40px" ref="form">
<!--      <el-row>-->
<!--        <el-col :span="15">-->
<!--          <el-form-item label="旧的密码" prop="oldPassword">-->
<!--            <el-input type="password" v-model="form.oldPassword" placeholder="旧密码"></el-input>-->
<!--          </el-form-item>-->
<!--        </el-col>-->
<!--      </el-row>-->
      <el-row>
        <el-col :span="15">
          <el-form-item label="新的密码" prop="newPassword" >
            <el-input type="password" v-model="form.newPassword" placeholder="数字和字母组成"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="15">
          <el-form-item label="确认新密码" prop="againPassword">
            <el-input type="password" v-model="form.againPassword" placeholder="数字和字母组成"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="checkUpdatePwd()">提 交</el-button>
        <el-button @click="close">取 消</el-button>
      </span>
  </el-dialog>
</template>

<script>
export default {
name: "emp-pwd-update",
  data() {
    let checkPwd = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'));
      } else if (value !== this.form.newPassword) {
        callback(new Error('两次输入密码不一致!'));
      } else {
        callback();
      }
    };
    return {
      form: {
        //公司名
        oldPassword:"",
        newPassword:"",
        againPassword:"",
        empId:JSON.parse(localStorage.getItem("emp")).id,
        tenantCrop:localStorage.getItem("tenantCrop"),
      },
      rules:{
        // oldPassword: [
        //   { required: true, message: '请输入旧密码', trigger: 'blur' },
        // ],
        newPassword: [
          { required: true, message: '请输入新密码', trigger: 'blur' },
          {pattern:/^\w+$/,message: "密码格式不正确"}
        ],
        againPassword: [
          { validator:checkPwd, trigger: 'blur' },
        ],
      },
      dialogState: this.state,
    }
  },
  props: {
    state: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  methods: {
    //检查订单校验
    checkUpdatePwd:function (){
      this.$refs['form'].validate((valid) => {
        if (valid){
          this.updatePwd();
        }
      })
    },
    //添加订单
    updatePwd: function () {
      let value="是否确认修改密码?"
      this.$confirm(value, '修改密码:', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      }).then(() => {
        this.$axios({
          method: "POST",
          url: "/emp/changePwdForm",
          params: this.form
        }).then(response => {
          if (response.data.code === 200) {
            this.$message.success("修改成功!")
            let self=this;
            setTimeout(function (){self.logOut()},1500)
          } else {
            this.$message.error(response.data.msg)
          }
        });
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消'
        });
      });
    },
    logOut(){
      localStorage.clear();
      this.$emit("clearAll")
      this.$router.push("/login");
    },
    close:function (){
      this.$emit("close",false);
    }
  }
}
</script>

<style scoped>

</style>