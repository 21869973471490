<template>
  <div>
    <template v-for="item in items">
      <el-submenu :index="item.url" v-if="item.child!==undefined && item.child.length > 0" :key="item.id">
        <template slot="title">
          <el-tooltip :content='item.annotation' placement="top">
            <i :class="item.icon" ></i>
          </el-tooltip>
          <span>{{ item.title }}</span>
        </template>
        <sub-item :items="item.child" />
      </el-submenu>
      <el-menu-item :index="item.url" v-else :key="item.id">
        <el-tooltip :content='item.annotation' placement="top" >
          <i :class="item.icon"></i>
        </el-tooltip>
          <span slot="title">{{ item.title }}</span>
      </el-menu-item>
    </template>
  </div>
</template>

<script>
export default {
  name:"sub-item",
  props:{
    items:Array
  },
}
</script>

<style scoped>

</style>